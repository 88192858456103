@import "../../../styles/variables";

.card {
    border: none!important; 
    background-color: $card-background!important; 
}

.card-body {
    background-color: $card-body-background!important; 
    color: whitesmoke!important; 
}

.card-body .secondary {
    background-color: $card-body-secondary!important; 
}

.card-header {
    background-color: $card-header-background!important; 
    color: whitesmoke!important; 
    position: relative!important; 
    display: flex!important; /* Flexbox for aligning title and buttons */
    justify-content: space-between!important; /* Space between title and button container */
    align-items: center!important; /* Center items vertically */
}

.card-header-buttons {
    display: flex!important; /* Flexbox layout for buttons */
    gap: 10px!important; /* Space between each button */
}

.card-header-button {
    border: none!important; 
    background-color: transparent!important; 
    color: white!important; 
    cursor: pointer!important; 
    display: inline-flex!important; /* Ensure buttons respect gap and align correctly */
    align-items: center!important; /* Center the icon vertically */
    justify-content: center!important; 
    padding: 0!important; /* Remove default padding */
}

.card-header-button:hover {
    color: $main-green-hover!important; 
}

.card-header-button:active {
    color: #999999!important; /* Darken the icon color when the button is active */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent overflow */
}

.modal-content {
    box-sizing: border-box; /* Include padding and border in dimensions */
    background-color: $card-body-background!important; /* Match modal body background color */
    border-radius: 8px;
    border: 0.5px solid #40e0d0!important; /* Thin turquoise border */
    width: 75vw; /* Modal width is 75% of the viewport */
    max-width: 75vw; /* Maximum width */
    height: auto; /* Dynamic height */
    max-height: 85vh; /* Maximum height is 75% of the viewport height */
    overflow: hidden; /* Prevent content overflow */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    flex-direction: column; /* Stack header and body */
}

.modal-header {
    background-color: $card-header-background!important; /* Matches card header color */
    color: whitesmoke!important; /* White text */
    display: flex;
    justify-content: space-between; /* Space between title and close icon */
    align-items: center; /* Vertically align items */
    padding: 10px 15px!important; /* Correct padding for a spacious header */
    border-bottom: 1px solid #ddd; /* Subtle bottom border */
    font-size: 1.2rem; /* Title font size */
    font-weight: bold; /* Bold title text */
    margin: 0; /* Reset margin */
    box-sizing: border-box; /* Ensure padding doesn't affect layout */
}

.modal-header .btn-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem; /* Larger size for clarity */
    color: whitesmoke!important; /* White close icon */
    display: inline-flex; /* Center icon */
    align-items: center;
    justify-content: center;
    padding: 5px; /* Add spacing around the close button */
}

.modal-header .btn-close:hover {
    color: #ff6961!important; /* Red hover effect for close icon */
}

.modal-body {
    background-color: $card-body-background!important; /* Match card body background color */
    padding: 30px!important; /* Ensure proper padding inside the modal body */
    overflow-y: auto; /* Enable vertical scrolling for overflowing content */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    flex-grow: 1; /* Allow body to take up available space */
    word-wrap: break-word; /* Break long content */
    box-sizing: border-box; /* Include padding in dimensions */
}

.modal-body * {
    margin: 0; /* Reset margin for child elements */
    padding: 0; /* Reset padding for child elements */
}