.textArea {
    resize: none; // Prevent resizing
    overflow-x: hidden; // Hide horizontal scrollbar
  
    &.scrollable {
      overflow-y: auto; // Show vertical scrollbar when needed
    }
  
    &.nonScrollable {
      overflow-y: hidden; // Hide vertical scrollbar
    }
  }