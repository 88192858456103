@import './_variables';
@import './fonts';

html {
  min-height: 95vh; /* Ensure it covers the full viewport height */
  background-color: $footer-color;
  margin: 0;
  padding: 0;
  font-size: 14px;
  overflow-x:hidden;
}

body {
  background-color: $background-color;
  font-family: 'Space Grotesk', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
