// Defining color variables
$main-green: #73fad9;
$main-green-hover: #b0f6defc;
$secondary-blue:#004f70;
$secondary-blue-hover:#003f5a;
$darker-theme-color: #1c2937;
$background-color: #243B53;
$footer-color: #1c2937;
$card-background:#26394d;
$card-body-background:#203245;
$card-body-secondary:#41556b;
$card-header-background:#1c2937;
$menu-background:#212529;
$form-disabled-background:#d4d0d0;
$form-disabled-color:#495057;
$form-control-focus-color:rgba(58, 163, 114, 0.25);
$alert-color: #e74b58;
$link-color: #52a5fe;
$link-color-hover: #87cefa;

