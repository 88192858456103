@import "../../../styles/variables";

.verticalMenu{
    background-color: $background-color;
}
.menuHeader {
    font-family: 'Space Grotesk', sans-serif;
    color:whitesmoke;
}
.menuLink {
    text-decoration: none;
    font-family: 'Space Mono', monospace;
    color: $main-green; /* Use the variable */
    font-size: 10px;
}
.menuIcon {
    display: inline-block;
    vertical-align: top;
    height: 0.5em;
    margin-right: 0.5em;
    font-weight: bold;
    color:whitesmoke;
}
