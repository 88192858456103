@import "../../../styles/variables";
.btn-primary {
    background-color: $main-green!important; /* Use the variable */
    color: #000000!important; /* black color for the button text */
    border:1px solid $main-green!important;
    transition: background-color 0.3s, color 0.3s!important;
}
.btn-primary:hover {
    background-color: $main-green-hover!important;
    color: #000000!important;
}

.btn-secondary {
    background-color: $secondary-blue!important; /* Use the variable */
    color: whitesmoke!important; /* black color for the button text */
    border:1px solid $secondary-blue!important;
    transition: background-color 0.3s, color 0.3s!important;
}
.btn-secondary:hover {
    background-color: $secondary-blue-hover!important; 
    color:whitesmoke!important;
}

.btn {
    &:disabled {
        background-color: #a39d9d!important; // Gray background
        color: #565656!important; // Darker gray text
        border-color: #262626!important; // Gray border to match

        // Prevent the button from looking clickable
        cursor: not-allowed;
    }
}
  