// Space Grotesk - Regular
@font-face {
    font-family: 'Space Grotesk';
    src: url('/fonts/SpaceGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

@font-face {
    font-family: 'Space Grotesk';
    src: url('/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  // Space Grotesk - Medium
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/fonts/SpaceGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  // Space Grotesk - SemiBold
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  // Space Grotesk - Bold
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/fonts/SpaceGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  // Space Mono - Regular
  @font-face {
    font-family: 'Space Mono';
    src: url('/fonts/SpaceMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  

  