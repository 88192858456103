@import 'variables';
.form-control:focus {
    border-color: $main-green!important; 
    box-shadow: 0 0 0 0.25rem $form-control-focus-color!important; /* Adjust the RGBA value to match your green's transparency */
}
.form-control:disabled {
    background-color: $form-disabled-background!important; /* Use your desired shade of gray */
    color: $form-disabled-color!important;
}
// style for class indexpage, should give white font
.indexpage {
    color: white;
    font-family: 'Space Grotesk', sans-serif;
}
.spinner-border {
    color: $main-green;
}

.full-vh-height {
    height: 100vh !important;
}

/* Dark theme for Bootstrap modal */
.modal-dark {
    .modal-content {
      background-color: $card-background; /* Use your defined card background color */
      color: #ffffff; /* White text */
      border: 1px solid $secondary-blue; /* Added subtle border */
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Added shadow for more depth */
    }
  
    .modal-header {
      border-bottom: 1px solid $card-body-secondary; /* Use a secondary color for borders */
      background-color: $card-header-background; /* Use your defined card header background */
    }
  
    .modal-title {
      color: $link-color; /* Brightened the title color for better focus */
    }
  
    .modal-body {
      background-color: $card-body-background; /* Use your defined card body background color */
    }
  
    .modal-footer {
      border-top: 1px solid $card-body-secondary; /* Use a secondary color for borders */
      background-color: $card-header-background; /* Consistent with the header */
    }
  
    .btn-close {
      filter: invert(1); /* Invert color for close button */
    }
  
    .btn-secondary {
      background-color: $secondary-blue;
      border-color: $secondary-blue;
      &:hover {
        background-color: $secondary-blue-hover;
        border-color: $secondary-blue-hover;
      }
    }
  
    .btn-danger {
      background-color: $alert-color;
      border-color: $alert-color;
      &:hover {
        background-color: lighten($alert-color, 5%); /* Slightly lighter on hover for emphasis */
        border-color: lighten($alert-color, 5%);
      }
    }
    
    .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.85); /* Darkened backdrop */
    }
  
    .modal-dialog {
      animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in animation */
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }