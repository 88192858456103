@import "../../../styles/variables";
@import '../../../styles/fonts';
.file-drop-area {
    border: 2px dashed #25c8a8;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    background-color: #c4f6e5;
    &:hover {
        background-color: #80d4ab;
    }
    box-sizing: border-box;
}

.drop-area-text {
    font-size: 14px;
    color: #666;
    font-family: "Space Mono";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    text-align: center;
    line-height: 1.2; /* Adjust line height to reduce space between lines */
}

.drop-area-text i {
    margin-bottom: 2px; /* Adjust margin-bottom to reduce space between icon and text */
}

/* Additional styles for flex container and table */
.d-flex {
    display: flex;
    flex-direction: column;
}

.flex-grow-1 {
    flex-grow: 1;
}

.table-custom {
    width: 100%;
    border-spacing: 0 2px;
}

.table-custom tr {
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
}