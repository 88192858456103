@import "../../styles/variables.scss";

.document-link {
    color: $link-color; /* Define your link color */
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: $link-color-hover; /* Define your hover color */
    }
}