@import "../../styles/variables.scss";
.high-importance {
    color: $alert-color;
}
.normal-importance {
    color: inherit; /* Keeps the default color */
}
.divider {
    border: 0;
    border-top: 1px solid white;
    margin: 20px 0; /* Adjust spacing as needed */
}
.toggle-link {
    color: $link-color; /* Lighter blue color */
    text-decoration: underline;
    cursor: pointer;
}
.toggle-link:hover {
    color: $link-color-hover; /* Even lighter blue on hover */
}
// for class promtAnswer I want to change background color to a little bit more subtile color
.promptAnswer {
    background-color: #323f4d;
}



