@import '../../../styles/variables';
@import '../../../styles/fonts';

// Styles for the JobList component
.table-custom {
    width: 100%;
    border-collapse: collapse;
    color:white!important;
    background-color: none!important; /* Dark blue color for the table */
    padding: 0;
    font-family: 'Space Mono', sans-serif;
}
  
  .table-custom td {
    text-align: left; 
  }
  
  .table-custom .selected-row {
    background-color: $main-green;/* Light blue color for selected rows */
    color:black;
  }
  
  .overflow-auto {
    max-height: 100%;
    overflow-y: auto;
  }
  
  .table-custom .tr{
    border-top: 1px solid white !important; /* White border on top of each row */
    border-bottom: 1px solid white !important; /* White border below each row */
  }

  .table-custom tr:hover {
    background-color: $secondary-blue; /* Light gray color for hover, change as needed */
    color:white;
  }