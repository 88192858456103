@import '../../styles/variables';

.footer{
  padding: 20px 0;
  color: whitesmoke;
  background-color: $footer-color;
  border-top: 1px solid rgb(28, 28, 28); 

  .container {
    text-align: center;
    margin: 0 auto;
  }
}