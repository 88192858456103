.status-component {
    
    .progress {
        margin-top: 10px;
        height:25px;

    }

    .progress-bar {
        width:25%;
        color:black;
    }
}