@import "../../styles/variables";
.chat-content {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto; /* Allows vertical scrolling */
}

.chat-bubble-left, .chat-bubble-right {
    max-width: 80%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    position: relative; /* Needed for absolute positioning of pseudo-elements */
}

.chat-bubble-left {
    align-self: flex-start;
    background-color: #4a76a8;
    margin-right: auto;
}

.chat-bubble-left::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -7px; /* Adjust based on bubble curvature */
    top: 10px; /* Adjust to align with the middle of the bubble */
    border-style: solid;
    border-width: 5px 8px 5px 0;
    border-color: transparent #4a76a8 transparent transparent;
}

.chat-bubble-right {
    align-self: flex-end;
    background-color: #4caf50;
    margin-left: auto;
}

.chat-bubble-right::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: -7px; /* Adjust based on bubble curvature */
    top: 10px; /* Adjust to align with the middle of the bubble */
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent #4caf50;
}

.chat-content::-webkit-scrollbar {
    width: 0; /* Hides the scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
    height: 0; /* Hides the scrollbar for horizontal scroll (if needed) */
}

.chat-content {
    scrollbar-width: none; /* Hides the scrollbar for Firefox */
}

/* Add this for text inside the bubble to preserve new lines */
.chat-text {
    white-space: pre-wrap; /* Preserve new lines and wrap text */
    word-wrap: break-word; /* Ensure long words break properly */
}

/* Copy icon styles */
.copy-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem; /* Adjust size as needed */
    color: lightgray; /* Default color */
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

.copy-icon:hover {
    color: gray; /* Slightly darker gray on hover */
}

.chat-bubble-left .copy-icon {
    right: -30px; /* Position on the right of left-aligned bubbles */
}

.chat-bubble-right .copy-icon {
    left: -30px; /* Position on the left of right-aligned bubbles */
}

/* Selected bubble styles */
.chat-bubble-selected {
    background-color: $link-color!important; /* Light gray background for selected bubbles */
    transition: background-color 0.2s ease-in-out;
}